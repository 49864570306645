import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { app } from '@getpopsure/private-constants';
import Session from '@getpopsure/session';

import {
  getIsLastQuestion,
  getQuestionAfter,
} from 'reducers/questionnaire/order';
import {
  answeredQuestion as answeredQuestionAction,
  answeredQuestion,
  AnsweredQuestionAction,
  cleanQuestionsAction,
  clearQuestionnaire,
} from 'actions/questionnaire';

import {
  selectAgentMetadata,
  selectOccupation,
  selectQuestionnaireSelector,
  selectQuestionnaireWithoutUnecessaryAnswers,
} from 'reducers/selectors';
import { SignatureAction } from 'actions/signature';
import * as api from 'services/api';
import { routes } from 'routes';
import {
  RequestType,
  setRequestErrored,
  setRequestFinished,
  setRequestInProcess,
} from 'actions/request';
import { getBlocker, pathForBlocker } from 'reducers/questionnaire/blocker';
import { AppState } from 'reducers';
import browserHistory from 'util/browserHistory';
import { autofillAnswerFromUserAction } from 'actions/session';
import { LocationDescriptorObject } from 'history';
import { verifyReferralCode } from 'features/referralEngine/sagas';
import { getValidReferralCode } from 'features/referralEngine/selectors';
import { updateReferralInfo } from 'features/referralEngine/actions';
import { paramsSetUrl } from 'util/paramsSetUrl';
import { PostCheckoutResponse } from 'models';
import { trackConversion } from '@getpopsure/analytics';

export function push(path: string | LocationDescriptorObject) {
  browserHistory.push(path);
}

export function redirectToExternalUrl(url: string) {
  return (window.location.href = url);
}

export function* postQuestionnaire(): Generator<any, any, any> {
  const request: RequestType = 'SUBMIT_QUESTIONNAIRE';
  yield put(setRequestInProcess(request));
  try {
    const questionnaire: ReturnType<
      typeof selectQuestionnaireWithoutUnecessaryAnswers
    > = yield select(selectQuestionnaireWithoutUnecessaryAnswers);

    const agent: ReturnType<typeof selectAgentMetadata> = yield select(
      selectAgentMetadata
    );

    const email = questionnaire.email;

    if (!email) {
      throw new Error(
        '[Questionnaire] can’t submit a questionnaire withtout email'
      );
    }

    const {
      data: { userExists },
    } = yield call(api.verifyCustomerEmail, email);

    const isAuthenticated = yield Session.isAuthenticated;

    if (!isAuthenticated && !userExists) {
      yield call(api.createUser, {
        email,
        firstName: questionnaire.firstName,
        lastName: questionnaire.lastName,
        dateOfBirth: questionnaire.dateOfBirth,
        gender: questionnaire.gender,
      });
    }

    const { data } = yield call(
      api.postPublicHealthQuestionnaire,
      questionnaire,
      agent
    );

    yield put(setRequestFinished(request));

    const questionnaireId = data.id;
    yield put(answeredQuestion('questionnaireId', questionnaireId));

    return { questionnaireId, email };
  } catch (error) {
    yield put(setRequestErrored(request, error));
    return undefined;
  }
}

export function* onCheckoutSuccess(): Generator<any, any, any> {
  const { policyId } = yield take('CHECKOUT_SUCCESS');
  const referralCode: string | undefined = yield select(getValidReferralCode);

  const occupation: ReturnType<typeof selectOccupation> = yield select(
    selectOccupation
  );
  const isStudent = occupation === 'UNIVERSITY_STUDENT';

  /**
   * Clear referral code if it was used
   */
  if (referralCode) {
    yield put(updateReferralInfo({ referralCode: undefined }));
  }

  /**
   * Clear questionnaire
   */
  yield put(clearQuestionnaire());

  /**
   * Track successful conversion
   */
  yield call(trackConversion, {
    vertical: 'PUBLIC_HEALTH',
    policy_id: policyId,
    commission_group: '1',
  });

  /**
   * Track successful financeAds conversion
   */
  yield call(api.trackFinanceAdsConversion, {
    policyId,
  });

  /**
   * Students need to complete the Student Checklist to be able to get covered.
   * Therefore, redirect them to the policy page on App to complete the checklist.
   *
   * Non-students will see a page with the provider's next steps.
   */
  if (isStudent) {
    yield call(push, routes.studentNextSteps.path);
    yield put(redirectUserToApp(`${app.myPolicies}/${policyId}`));
  } else {
    yield call(push, routes.nextSteps.path);
  }
}

export function* postCheckoutSignature(
  signature: string,
  questionnaireId: string
): Generator<any, any, any> {
  const request: RequestType = 'SUBMIT_SIGNATURE';
  yield put(setRequestInProcess(request));
  try {
    const referralCode: string | undefined = yield select(getValidReferralCode);

    yield call(api.postSignature, signature);

    const response: AxiosResponse<PostCheckoutResponse> = yield call(
      api.postCheckout,
      {
        questionnaireId,
        ...(referralCode ? { referralCode } : {}),
      }
    );

    yield put(setRequestFinished(request));

    yield put(setCheckoutSuccess(response.data.policy.id));
  } catch (error) {
    yield put(setRequestErrored(request, error));
  }
}

export function setCheckoutSuccess(policyId: string) {
  return { type: 'CHECKOUT_SUCCESS', policyId };
}

export function redirectUserToApp(url: string) {
  return { type: 'REDIRECT_USER', url };
}

export function* redirectUserWithUrlParams(): Generator<any, any, any> {
  while (true) {
    const { url }: { url: string } = yield take('REDIRECT_USER');
    const redirectUrl = paramsSetUrl(url, [
      { key: 'signupSuccess', value: 'publicHealth' },
      { key: 'showDental', value: '1' },
    ]);

    yield call(redirectToExternalUrl, redirectUrl);
  }
}

export function* skipCurrentQuestion(): Generator<any, any, any> {
  while (true) {
    const { questionId } = yield take('SKIPPED_QUESTION');

    const nextQuestion: ReturnType<
      typeof getQuestionAfter
    > = yield select((state: AppState) =>
      getQuestionAfter(state.questionnaire, questionId)
    );

    yield call(push, nextQuestion);
  }
}

export function* redirectAfterAnsweredQuestion(): Generator<any, any, any> {
  while (true) {
    const { questionId, redirect }: AnsweredQuestionAction = yield take(
      'ANSWERED_QUESTION'
    );

    if (redirect === false) {
      continue;
    }

    yield put(cleanQuestionsAction());

    const nextQuestion: ReturnType<
      typeof getQuestionAfter
    > = yield select((state: AppState) =>
      getQuestionAfter(state.questionnaire, questionId)
    );

    const isLastQuestion: ReturnType<
      typeof getIsLastQuestion
    > = yield select((state: AppState) =>
      getIsLastQuestion(state.questionnaire, questionId)
    );

    const blocker: ReturnType<
      typeof getBlocker
    > = yield select((state: AppState) =>
      getBlocker(state.questionnaire, questionId)
    );

    if (isLastQuestion === true) {
      const result = yield call(postQuestionnaire);

      if (isLastQuestion === true && result) {
        yield call(push, routes.signature.path);
      }
    } else if (blocker !== undefined) {
      if (blocker === 'IS_SPOUSE') {
        yield call(postQuestionnaire);
      }
      const path = pathForBlocker(blocker);
      yield call(push, path);
    } else if (nextQuestion !== undefined) {
      yield call(push, nextQuestion);
    }
  }
}

export function* signOut(): Generator<any, any, any> {
  while (true) {
    const request: RequestType = 'signout.user';
    yield take('SIGN_OUT');
    yield put(setRequestInProcess(request));

    try {
      yield call(api.signOutUser);
      yield put(setRequestFinished(request));
    } catch (error) {
      yield put(setRequestErrored(request, error));
    }
  }
}

export function* sendCheckoutSignature(): Generator<any, any, any> {
  while (true) {
    const { signature, questionnaireId }: SignatureAction = yield take(
      'SUBMITED_SIGNATURE'
    );
    yield postCheckoutSignature(signature, questionnaireId);
  }
}

export function* autofillAnswersFromUser({
  user,
}: ReturnType<typeof autofillAnswerFromUserAction>) {
  const questionnaire: ReturnType<
    typeof selectQuestionnaireSelector
  > = yield select(selectQuestionnaireSelector);

  if (user.email !== undefined) {
    yield put(answeredQuestionAction('email', user.email, false));
  }

  const questionnaireName = questionnaire?.name;

  if (
    questionnaireName?.firstName === undefined &&
    questionnaireName?.lastName === undefined
  ) {
    yield put(
      answeredQuestionAction(
        'name',
        {
          firstName: user?.firstName || '',
          lastName: user?.lastName || '',
        },
        false
      )
    );
  }

  if (user.gender !== undefined && questionnaire.gender === undefined) {
    if (user.gender === 'FEMALE') {
      yield put(answeredQuestionAction('gender', 'FEMALE', false));
    } else if (user.gender === 'MALE') {
      yield put(answeredQuestionAction('gender', 'MALE', false));
    }
  }

  if (
    user.dateOfBirth !== undefined &&
    questionnaire.dateOfBirth === undefined
  ) {
    yield put(answeredQuestionAction('dateOfBirth', user.dateOfBirth, false));
  }
}

export default function* root() {
  yield all([
    redirectUserWithUrlParams(),
    redirectAfterAnsweredQuestion(),
    skipCurrentQuestion(),
    sendCheckoutSignature(),
    signOut(),
    takeEvery('AUTOFILL_USER', autofillAnswersFromUser),
    verifyReferralCode(),
    onCheckoutSuccess(),
  ]);
}
